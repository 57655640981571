import { AuthProvider } from "@pankod/refine-core";
import { POST_LOGIN_REDIRECT } from "hashRouter/routeProvider";
import { AAD_LOGIN, fetchClientPrincipal, fetchUserIdentity, getRedirectionURL, purgeAadData } from "scripts/site";

export const AuthNProvider: AuthProvider = {
    login: async () => {
        if (!await fetchClientPrincipal()) {
            let loginURL = AAD_LOGIN;

            // Check if we are been redirected for specific page
            let redirectURL = getRedirectionURL(window.location.hash);
            if (redirectURL) {
                loginURL += POST_LOGIN_REDIRECT + redirectURL;
            }

            window.location.href = loginURL
            Promise.reject();
        }
        return Promise.resolve();
    },
    logout: () => {
        return Promise.resolve();
    },
    checkError: (error) => {
        if (error.status === 401) { // || error.status === 403) {
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: async () => {
        if (await fetchClientPrincipal()) {
            return Promise.resolve();
        }
        return Promise.reject();
    },
    getPermissions: () => Promise.reject(),
    getUserIdentity: async () => {
        const response = await fetchUserIdentity();
        const responseJson = await response?.json();
        if (response?.ok) {
            if (responseJson) {
                // Fire asynchrounous call to purge the AAD data. No need to wait for it to complete.
                if (responseJson?.aadPurgeRequired === true) {
                    purgeAadData(responseJson?.id);
                }
                return Promise.resolve(responseJson);
            }
        }

        const clientPrincipal = await fetchClientPrincipal();
        if (clientPrincipal) {
            // Check for AAD purge required in case of old deactivated ids
            if (responseJson?.aadPurgeRequired === true) {
                purgeAadData(responseJson?.id, false);
            }
            return Promise.resolve({
                id: clientPrincipal.userDetails,
                name: clientPrincipal.userDetails,
            });
        }
        return Promise.reject();
    },
};